export class CareerOptions {
  Category: string;

  Status?: string | undefined;

  constructor() {
    this.Category = 'Alla';
    this.Status = 'All';
  }
}
