import { useQuery } from '@tanstack/react-query';
import { careerKeys } from 'src/modules/career/hooks/careerKeys';
import { CareerAPI } from 'src/services/api/careerApi';
import { PagedResult } from 'src/services/api/helpers/pagedResult';
import { CareerOptions } from 'src/services/api/options/careerOptions';
import { CareerResponse } from 'src/services/api/response/careerResponse';

const careerApi = new CareerAPI();

export const getCareerItems = async (reqOptions: CareerOptions, searchString: string): Promise<CareerResponse[]> => {
  const res: PagedResult<CareerResponse> = await careerApi.GetAll(reqOptions);
  const sortedData = res.Results.sort((a, b) => b.PublishedDate.localeCompare(a.PublishedDate));
  if (searchString.length > 0) {
    const searchedData = sortedData?.filter((item: CareerResponse) => {
      return item.Heading.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1;
    });
    return searchedData;
  }
  return sortedData;
};

export const getCareerItemById = async (careerItemGuid: string): Promise<CareerResponse> => {
  const res: CareerResponse = await careerApi.Get(careerItemGuid);
  return res;
};

export const useFetchCareerItems = (status: string | undefined, searchString: string) => {
  const options = new CareerOptions();
  options.Status = status;
  return useQuery({
    queryKey: careerKeys.list(options, searchString),
    queryFn: () => getCareerItems({ ...options }, searchString),
    onSuccess: (data) => {
      // console.log(' ...data', data);
    },
  });
};

export const useFetchCareerItemById = (careerItemGuid: string) => {
  return useQuery({
    queryKey: careerKeys.byId(careerItemGuid),
    queryFn: () => getCareerItemById(careerItemGuid),
  });
};
