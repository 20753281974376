import { Dispatch } from 'react';
import { Module } from 'src/services/api/models';

export const SET_DOMAIN = 'SET_DOMAIN';
export const SET_MODULES = 'SET_MODULES';

export const setDomain = (domain: string) => {
  return async (dispatch: Dispatch<AppDispatchTypes>) => {
    dispatch({ type: SET_DOMAIN, payload: domain });
  };
};

export interface SetDomain {
  type: typeof SET_DOMAIN;
  payload: string;
}

export type AppDispatchTypes = SetDomain | SetTabModules;

export interface SetTabModules {
  type: typeof SET_MODULES;
  payload: Module[];
}

export const setModules = (modules: Module[]) => {
  return async (dispatch: Dispatch<AppDispatchTypes>) => {
    dispatch({ type: SET_MODULES, payload: modules });
  };
};
