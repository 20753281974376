import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Module } from 'src/services/api/models';
import { RootStateType } from 'src/services/redux/reducers';
import { RoutesEnum } from 'src/shared/enums/routes.enum';

/**
 * Typ för alla tillgängliga moduler
 */
export type ModulNames = 'News' | 'Career' | 'Calendar' | 'Chat' | 'Form' | 'Employee' | 'Progress' | 'Order';

/**
 * Hook för att hämta displayName för en modul.
 */
export const useModuleDisplayName = (moduleName: ModulNames): string => {
  const { t } = useTranslation('common');
  const { modules } = useSelector((state: RootStateType) => state.appState);

  // Hitta rätt modul
  const foundModule = modules.find((module: Module) => module.module === moduleName);

  // Validera displayName
  const displayName = foundModule?.displayName?.trim() || null;

  // Översättningsnyckel baserat på modulnamn
  const translationKey = `modules.${moduleName.toLowerCase()}`;

  // Returnera ett giltigt displayName eller översättning
  return displayName || t(translationKey) || moduleName;
};

export const getRouteForModule = (module: string) => {
  switch (module) {
    case 'News':
      return RoutesEnum.Start;
    case 'Calendar':
      return RoutesEnum.Calendar;
    //   case 'Chat':
    //     return RoutesEnum.;
    case 'Employee':
      return RoutesEnum.Employee;
    case 'Career':
      return RoutesEnum.Career;
    case 'Form':
      return RoutesEnum.Form;
    case 'Order':
      return RoutesEnum.LunchOrder;
    case 'Progress':
      return RoutesEnum.Progress;
    default:
      return RoutesEnum.Start; // Fallback-komponent
  }
};
