import { Link, useLocation } from 'react-router-dom';
import * as icons from '@mui/icons-material'; // För dynamiska moduler
import { List, ListItem, ListItemIcon, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PrefetchRoutes, Routes, usePrefetchRoutes } from 'src/services/helpers/prefetch';
import { RootStateType } from 'src/services/redux/reducers';
import { useSelector } from 'react-redux';
import { Colors } from './styles/colors';
import { getRoutePath } from './helper/getRoutePath';
import { getRouteForModule } from './helper/modulesHelper';

type NavigationMenuProps = {
  listItemDirection: 'row' | 'column';
  listDirection: 'row' | 'column';
  justifyContent: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  padding: number;
};

export const NavigationMenu = (props: NavigationMenuProps) => {
  const { listItemDirection, justifyContent, padding, listDirection } = props;
  const location = useLocation();
  const { t } = useTranslation();
  const currentPath = getRoutePath(location.pathname);
  const { modules } = useSelector((state: RootStateType) => state.appState);
  const prefetch = usePrefetchRoutes<Routes>();

  return (
    <List
      component='nav'
      sx={{
        '&.Mui-disabled': {
          pointerEvents: 'auto',
        },
        display: 'flex',
        flexDirection: listDirection,
        justifyContent: 'space-between',
        width: 650,
      }}
    >
      {modules
        ?.filter((item) => item.module !== 'Chat')
        .map((item) => {
          const Icon = icons[item.webIcon as keyof typeof icons];
          return (
            <ListItem
              sx={{
                p: padding,
                textAlign: 'center',
                display: 'flex',
                justifyContent: `${justifyContent}`,
              }}
              button
              key={item.module}
              component={Link}
              to={getRouteForModule(item.module)}
              onMouseEnter={
                item.module === 'Calendar' || item.module === 'Progress' || item.module === 'Career'
                  ? prefetch[item.module.toLocaleLowerCase() as keyof PrefetchRoutes<Routes>]
                  : undefined
              }
            >
              <Box display='flex' flexDirection={listItemDirection}>
                <ListItemIcon
                  sx={{
                    minWidth: 40,
                    justifyContent: 'center',
                    color: currentPath === getRouteForModule(item.module) ? Colors.ACCENT_DARK : '',
                  }}
                >
                  <Icon />
                </ListItemIcon>
                <Typography
                  variant='subtitle1'
                  sx={{
                    color: currentPath === getRouteForModule(item.module) ? Colors.ACCENT_DARK : '',
                  }}
                >
                  {item.displayName && item.displayName?.length > 0 ? item.displayName : t(`common:modules.${item.module.toLowerCase()}`)}
                </Typography>
              </Box>
            </ListItem>
          );
        })}
    </List>
  );
};
