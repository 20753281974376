import { Module } from 'src/services/api/models';
import { AppDispatchTypes, SET_DOMAIN, SET_MODULES } from '../actions/appActions';

interface IAppState {
  domain: string;
  modules: Module[];
}

const initState: IAppState = {
  domain: '',
  modules: [],
};

export const appReducer = (state = initState, action: AppDispatchTypes): IAppState => {
  switch (action.type) {
    case SET_DOMAIN: {
      return {
        ...state,
        domain: action.payload,
      };
    }
    case SET_MODULES: {
      return {
        ...state,
        modules: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};
